import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import { Add, ExpandMore } from "@material-ui/icons";
import ModalBasic from "components/utils/ModalBasic";
import { PDFRMPReviewTasks, PDInitialReviewTasks, PDPreProductionReviewTasks, PDProjectChecklistInterface, PDProjectChecklistTypes, PDProjectInterface, PDTechReviewTypes } from "interfaces/ProductDevelopment";
import React from "react";
import { makeAxiosCall } from "utils";
import PDProjectChecklist from "./ProjectChecklist";


interface TechAreaProps {
    project: PDProjectInterface,
    setProject: (project: PDProjectInterface) => void,
    refreshData: () => void,
}

const TechArea = ({ project, setProject, refreshData }: TechAreaProps) => {
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);
    const [newReviewType, setNewReviewType] = React.useState<PDProjectChecklistTypes>(PDProjectChecklistTypes.Initial);
    const [newReviewBaubleId, setNewReviewBaubleId] = React.useState<string>("");
    const [creatingList, setCreatingList] = React.useState<boolean>(false);
    const [showDeleteWarning, setShowDeleteWarning] = React.useState<boolean>(false);
    const [listToDelete, setListToDelete] = React.useState<PDProjectChecklistInterface>(null);
    const [deletingList, setDeletingList] = React.useState<boolean>(false);

    const openModal = () => {
        setModalOpen(true);
    }

    const closeModal = () => {
        setNewReviewType(PDProjectChecklistTypes.Initial);
        setNewReviewBaubleId("");
        setCreatingList(false);
        setModalOpen(false);
    }

    const closeDeleteWarning = () => {
        setListToDelete(null);
        setShowDeleteWarning(false);
    }

    const createNewChecklist = async () => {
        setCreatingList(true);
        let taskList = [];
        switch (newReviewType) {
            case PDProjectChecklistTypes.PreProduction:
                taskList = PDPreProductionReviewTasks;
                break;
            case PDProjectChecklistTypes.FRMP:
                taskList = PDFRMPReviewTasks;
                break;
            default:
                taskList = PDInitialReviewTasks;
                break;
        }

        let response = await makeAxiosCall(
            "post",
            "pd/checklists",
            {
                projectId: project.id,
                type: newReviewType,
                baubleId: newReviewBaubleId,
                tasks: taskList,
            }
        ).catch((error) => {
            console.error(error);
        });

        if (response && response.status == 200) {
            closeModal();
            refreshData();
        } else {
            console.log("Something went wrong:");
            console.log(response);
        }

        setCreatingList(false);
    }

    const _deleteChecklist = async () => {
        setDeletingList(true);
        let response = await makeAxiosCall(
            "delete",
            `pd/checklists/${listToDelete.id}`
        ).catch((error) => {
            console.error(error);
        });

        if (response && response.status == 200) {
            closeDeleteWarning();
            refreshData();
        } else {
            console.log("Something went wrong:");
            console.log(response);
        }

        setDeletingList(false);
    }

    const _buildReview = (review: PDProjectChecklistInterface) => {
        return (
            <Grid
                item
                xs={12}
                key={`${project.id}-${review.id}-review`}
            >
                <Accordion
                    elevation={1}
                    style={{
                        backgroundColor: "whitesmoke"
                    }}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        style={{
                            fontWeight: "bold",
                            boxShadow: "none",
                            alignItems: "center",
                        }}
                        className="line-item"
                    >
                        {review.type} {review.createdAt && (" - " + review.createdAt.toLocaleDateString())}
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container>
                            <PDProjectChecklist
                                checklist={review}
                                project={project}
                                setProject={setProject}
                                refreshData={refreshData}
                            />
                            <Grid item container xs={12}>
                                <Grid item xs={12} md={9}></Grid>
                                <Grid item xs={12} md={3}>
                                    <Button
                                        type="submit"
                                        fullWidth={true}
                                        variant="contained"
                                        className="btn red"
                                        onClick={() => {
                                          setListToDelete(review);
                                          setShowDeleteWarning(true);
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        );
    }

    const _buildReviews = (reviews: PDProjectChecklistInterface[]) => {
        return (
            <Grid container spacing={1}>
                {reviews.map((review) => _buildReview(review))}
            </Grid>
        );
    }

    const _buildSampleView = (name: string, reviews: PDProjectChecklistInterface[]) => {
        return (
            <Grid item xs={12} key={`${project.id}-${name}-reviews`}>
                <Accordion
                    elevation={1}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        style={{
                            fontWeight: "bold",
                            boxShadow: "none",
                            alignItems: "center",
                        }}
                        className="line-item"
                    >
                        {name}
                    </AccordionSummary>

                    <AccordionDetails>
                        {_buildReviews(reviews)}
                    </AccordionDetails>
                </Accordion>
            </Grid>
        );
    }

    const _buildReviewDisplay = () => {
        let techChecklists = project.checklists.filter((list) => list.type !== PDProjectChecklistTypes.Progress);
        let grids = [];

        let unlinkedReviews = techChecklists.filter((list) => list.baubleId == null);
        if (unlinkedReviews.length) {
            grids.push(_buildSampleView("General", unlinkedReviews));
        }

        let linkedSamples = Array.from(new Set(techChecklists.map((list) => list.baubleId)));
        for (let i = 0; i < linkedSamples.length; i++) {
            let sampleId = linkedSamples[i];
            if (sampleId != null) {
                let sampleName = project.baubles.find((bauble) => bauble.id == sampleId).name;
                let sampleReviews = techChecklists.filter((list) => list.baubleId == sampleId).sort((a, b) => a.id - b.id);
                grids.push(_buildSampleView(sampleName, sampleReviews));
            }
        }

        return grids;
    }

    return (
        <>
            <Grid container alignItems="center" spacing={2} key="review-grid">
                <Grid item xs={12} md={9} key="review-heading">
                    <h2>Tech Review</h2>
                </Grid>
                <Grid item xs={12} md={3} key="review-button">
                    <Button
                        type="submit"
                        fullWidth={true}
                        variant="contained"
                        color="primary"
                        className="btn blue"
                        onClick={openModal}
                    >
                        Add <Add />
                    </Button>
                </Grid>
                {_buildReviewDisplay()}
            </Grid>
            <ModalBasic
                open={modalOpen}
                close={closeModal}
                unmountOnExit
            >
                <div style={{ padding: 25, margin: 10, minWidth: 500, maxWidth: 1000 }}>
                    <h3>New Evaluation</h3>
                    <Grid container className="address-line" style={{ textAlign: "center" }} alignItems="center" spacing={2}>
                        <Grid item xs={12} key="new-review-type">
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel id="tech-review-type-label">Type</InputLabel>
                                <Select
                                    labelId="tech-review-type-label"
                                    id="tech-review-type-select"
                                    value={newReviewType}
                                    onChange={(event: any) => {
                                        setNewReviewType(event.target.value);
                                    }}
                                >
                                    {PDTechReviewTypes.map((type) => {
                                        return (<MenuItem value={type} key={`type-select-${type}`}>{type}</MenuItem>);
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} key="new-review-sample">
                            <FormControl
                                fullWidth={true}
                            >
                                <InputLabel id="tech-review-bauble-label">Sample</InputLabel>
                                <Select
                                    labelId="tech-review-bauble-label"
                                    id="tech-review-bauble-select"
                                    value={newReviewBaubleId}
                                    disabled={project.baubles.length == 0}
                                    onChange={(event: any) => {
                                        setNewReviewBaubleId(event.target.value);
                                    }}
                                >
                                    <MenuItem value={""} key={"bauble-select-none"}>None</MenuItem>
                                    {project.baubles.map((bauble) => {
                                        return (<MenuItem value={bauble.id} key={`bauble-select-${bauble.id}`}>{bauble.name}</MenuItem>);
                                    })}
                                </Select>
                                {project.baubles.length == 0 && (
                                    <FormHelperText>No samples in project: Go to the "Samples" tab if you need to add one.</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} key="new-review-create-button">
                            <Button
                                type="submit"
                                style={{ width: "100%" }}
                                variant="contained"
                                color="primary"
                                onClick={createNewChecklist}
                                className="btn blue"
                            >
                                {creatingList ?
                                    <CircularProgress
                                        color="inherit"
                                        size={25}
                                    /> :
                                    "Create"
                                }
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </ModalBasic>
            <Dialog
                open={showDeleteWarning}
                onClose={closeDeleteWarning}
            >
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent
                style={{
                    minWidth: 500,
                    minHeight: 140,
                }}
                >
                    No going back!
                </DialogContent>
                <DialogActions>
                  <Button
                      onClick={_deleteChecklist}
                      variant="contained"
                      className="btn red"
                  >
                      {deletingList ? 
                          <CircularProgress 
                              color="inherit"
                              size={25}
                          /> : 
                          "Delete"
                      }
                  </Button>
                  <Button
                      onClick={closeDeleteWarning}
                      variant="contained"
                      className="btn"
                  >
                      Cancel
                  </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default TechArea;