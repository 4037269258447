import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Grid,
} from "@material-ui/core";

import _ from "lodash";
import React, { useState } from "react";
import { UserContext } from "context/User";
import ModalBasic from "components/utils/ModalBasic";
import { CancelOutlined, CheckBoxOutlineBlank, CheckBoxOutlined, ExpandMore} from "@material-ui/icons";
import axios from "axios";
import { buildProductRegistration, ProductRegistrationInterface, ProductRegistrationProductInterface } from "interfaces/ProductRegistration";

const WarrantyReview = () => {
  const [registrations, setRegistrations] = useState<ProductRegistrationInterface[]>([]);
  const [input, setInput] = useState("");
  const [modal, setModal] = useState(false);
  const [loadingRegs, setLoadingRegs] = useState(false);
  const [currentRegistration, setCurrentRegistration] = useState<ProductRegistrationInterface>();
  const fetchRegistrations = () => {
    setLoadingRegs(true);
    axios.get(
      "https://atfullapi.blizzard.lighting/products/all-registrations",
    )
    .then((res) => {
      let regs: ProductRegistrationInterface[] = [];
      _.map(res.data, (r) => {
        let registration = buildProductRegistration(r);
        if(registration.products.length > 0) regs.push(registration);
      });
      setRegistrations(regs);
      setLoadingRegs(false);
    })
    .catch((err) => setLoadingRegs(false));
  };
  React.useEffect(() => {
    fetchRegistrations();
  }, []);

  const checkRegistration = (r: ProductRegistrationInterface) => {
    return r.products.every((rp) => rp.checked);
  };

  const updateRegisteredProduct = (prod: ProductRegistrationProductInterface, approval: boolean, purchaseDate: Date) => {
      const payload = {
        id: prod.id, // registered PRODUCT id NOT THE REGISTRATION ID!
        new: prod.new,
        approved: approval,
        purchaseDate: purchaseDate.toUTCString()
      }

      axios.post(
        "https://atfullapi.blizzard.lighting/products/registrations/warranty-approval",
        payload
      )
      .then((result) => {
        fetchRegistrations();
        console.log(result);
      })
      .catch((err) => console.log(err));
  };

  const renderRegisteredSingleProductRow = (product: ProductRegistrationProductInterface, registration: ProductRegistrationInterface) => {
    return (
      <Grid item xs={12} container style={{textAlign: "center"}}>
        <Grid item xs={4} style={{textAlign: "left", alignSelf: "center"}}>
          {product.product.name}
        </Grid>
        <Grid item xs={2} style={{alignSelf: "center"}}>
          {product.serialNumber}
        </Grid>
        <Grid item xs={2} style={{alignSelf: "center"}}>
          {product.warrantyExpiration.getMonth() + 1 + "/" + product.warrantyExpiration.getDate() + "/" + product.warrantyExpiration.getFullYear()}
        </Grid>
        <Grid item xs={2} style={{ padding: 12, alignSelf: "center" }}>
          Currently: {product.checked ? (product.warrantyExtensionDeclined ? "Declined" : "Approved") : "Unchecked"}
        </Grid>
        <Grid item xs={1} style={{ padding: 12 }}>
          <Button
            type="submit"
            style={{ width: "100%" }}
            variant="contained"
            onClick={() => {
              updateRegisteredProduct(product, true, registration.purchaseDate);
            }}
            color="primary"
            className="btn"
          >
            <CheckBoxOutlined />
          </Button>
        </Grid>
        <Grid item xs={1} style={{ padding: 12 }}>
          <Button
            type="submit"
            style={{ width: "100%" }}
            variant="contained"
            onClick={() => {
              updateRegisteredProduct(product, false, registration.purchaseDate);
            }}
            color="secondary"
            className="btn"
          >
            <CancelOutlined />
          </Button>
        </Grid>
      </Grid>
    );
  }

  const renderRegistrationDetails = (r: ProductRegistrationInterface) => {

    let productRows = [];

    for (let ri = 0; ri < r.products.length; ri++) {
      const product = r.products[ri];
      productRows.push(renderRegisteredSingleProductRow(product, r));
    }

    return (
      <Grid item xs={12} container>
        <Grid item xs={12} style={{fontWeight: "bold", textAlign: "center"}}>
          <h3>Products</h3>
        </Grid>
        <Grid container item xs={12} style={{fontWeight: "bold", textAlign: "center", borderBottom: "1px solid black"}}>
          <Grid item xs={4} style={{textAlign: "left"}}>
            Name
          </Grid>
          <Grid item xs={2}>
            SN
          </Grid>
          <Grid item xs={2}>
            Expiration Date
          </Grid>
          <Grid item xs={4}>
            Extention Approval
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {productRows}
        </Grid>
      </Grid>
    );
  };

  const renderRegistrations = () => {
    const searchInput = input.toLowerCase().replace(/[^a-zA-Z ]/g, " ");

    let searchResults: ProductRegistrationInterface[] = _.filter(registrations, (o: ProductRegistrationInterface) => {
      let serial = "";
      let name = "";
      let email = "";

      if (o.email !== null) {
        email = o.email;
      }
      if (o.name !== null) {
        name = o.name.toLowerCase();
      }

      const arr = [name, serial, email];

      const allTerms = arr.join(" ");

      return allTerms.includes(searchInput);
    });

    searchResults.sort((a, b) => b.purchaseDate.getTime() - a.purchaseDate.getTime());
    searchResults.sort((a, b) => (checkRegistration(b) ? 0 : 1) - (checkRegistration(a) ? 0 : 1));

    if (searchResults.length > 0) {
      return (
        <Grid container item xs={12} className="grid-search-body">
          {_.map(searchResults, (r: ProductRegistrationInterface, index: number) => {
            return (
              <Accordion key={"registration-" + r.id + "index" + index} style={{width: "100%"}}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Grid container>
                    <Grid item xs={1} className="grid-search-body" style={{alignSelf: "center"}}>
                      {checkRegistration(r) ? <CheckBoxOutlined /> : <CheckBoxOutlineBlank />}
                    </Grid>
                    <Grid item xs={6} style={{alignSelf: "center"}}>
                      {r.name != "No Name" && r.name != "anonymous" ? (r.name + " (" + r.email + ")") : r.email}
                    </Grid>
                    <Grid item xs={2} style={{textAlign: "center", alignSelf: "center"}}>{r.products.length > 0 ? (r.products.length + " Product" + (r.products.length > 1 ? "s" : "")) : "No Products"}</Grid>
                    <Grid item xs={2} style={{textAlign: "center", alignSelf: "center"}}>{r.purchaseDate.getMonth() + 1 + "/" + r.purchaseDate.getDate() + "/" + r.purchaseDate.getFullYear()}</Grid>
                    <Grid item xs={1} style={{textAlign: "center", alignSelf: "center"}}>
                      <Button
                        type="submit"
                        variant="outlined"
                        onClick={() => {
                          setModal(true);
                          setCurrentRegistration(r);
                        }}
                        color="primary"
                        className="btn"
                      >
                        <i className="fas fa-receipt"></i>
                      </Button>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>{renderRegistrationDetails(r)}</AccordionDetails>
              </Accordion>
            );
          })}
        </Grid>
      );
    } else return <></>;
  };
  return (
    <Grid id="warranty-list" container>
      <ModalBasic close={() => setModal(false)} open={modal}>
        {currentRegistration ? (
          <div className="add-prompt">
            {currentRegistration.receiptUrl && (
              <>
                <h3>Receipt</h3>
                <img
                  style={{ maxWidth: "1000px" }}
                  src={currentRegistration.receiptUrl}
                />
              </>
            )}
          </div>
        ) : (
          <h2>No Registration Selected</h2>
        )}
      </ModalBasic>
        <Grid container item xs={12}>
          <Grid container item xs={12} className="grid-search-headers" style={{paddingRight: 48, paddingBottom: 12}}>
            <Grid xs={1} item className="result-name">
                Checked
            </Grid>
            <Grid item xs={6}>
                Customer
            </Grid>
            <Grid item xs={2} style={{textAlign: "center"}}>
                Products
            </Grid>
            <Grid item xs={2} style={{textAlign: "center"}}>
                Purchase Date
            </Grid>
            <Grid item xs={1} style={{textAlign: "center"}}>
                Receipt
            </Grid>
          </Grid>

          {loadingRegs && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 30,
                width: "100%"
              }}
            >
              <CircularProgress />
            </div>
          )}
          {renderRegistrations()}
        </Grid>
        
    </Grid>
  );
};

export default WarrantyReview;
